import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const rencontrefugace = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Rencontre crépusculaire" />
    <h3 className='underline-title'>Rencontre crépusculaire</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Dix huit heures propices à la flânerie<br />
        Heures déambulatoires des Muses distributrices<br />
        Le lac farda sa face fripée d'Éole<br />
        Avala une fois sa langue clapotante<br />
        Soudain je la vois<br />
        Plutôt je l'entrevois<br />
        Phosphorescente<br />
        Informe-vaporeuse-fugace<br />
        Flambée mouvante<br />
      </p>
      <p>
        Serait-ce la fille de Nérée?<br />
        La belle Néréide ne sort point des eaux maritimes<br />
        C'est Polymnie déesse lyrique<br />
        Que fais-tu déesse?<br />
        Elle voulait un troubadour<br /> 
        Un chavelier dompteur<br />
      </p>
      <p>
        Je pris au lasso la Muse fringante<br />
        Et la soumis à la domestication du cordier<br />
        Elle s'apprivoisa au rythme du librettiste<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default rencontrefugace
